@font-face {
    font-family: 'LotaGrotesque-Regular';
    src: url('../fonts/LotaGrotesque-Regular.eot');
    src: url('../fonts/LotaGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/LotaGrotesque-Regular.svg#LotaGrotesque-Regular') format('svg'),
        url('../fonts/LotaGrotesque-Regular.ttf') format('truetype'),
        url('../fonts/LotaGrotesque-Regular.woff') format('woff'),
        url('../fonts/LotaGrotesque-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LotaGrotesque-Regular';
    src: url('../fonts/LotaGrotesque-SemiBold.eot');
    src: url('../fonts/LotaGrotesque-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/LotaGrotesque-SemiBold.svg') format('svg'),
        url('../fonts/LotaGrotesque-SemiBold.ttf') format('truetype'),
        url('../fonts/LotaGrotesque-SemiBold.woff') format('woff'),
        url('../fonts/LotaGrotesque-SemiBold.woff2') format('woff2');
    font-weight: 600;
}
